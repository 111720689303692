import { Component } from '@angular/core';
import { UserTypes } from '@pinnakl/shared/types';
import { AuthenticatorService } from '../../services/authenticator.service';

@Component({
  selector: 'auth-page',
  templateUrl: 'auth-page.component.html',
  styleUrls: ['./auth-page.component.scss']
})
export class AuthPageComponent {
  isExternalUser: boolean;

  constructor(private authenticator: AuthenticatorService) {
    this.isExternalUser = this.authenticator.userType === UserTypes.EXTERNAL;
  }
}
