import { AppNames } from '@pinnakl/shared/types';
import {
  authUrls,
  coreApiServerUrls,
  datastream1Urls,
  fileServiceUrls,
  firebaseConfigs,
  frontEndErrorServiceUrls,
  requestTimeoutPeriods,
  vdrFileAppHosts
} from './pinnakl-environment-options';
import { EnvNames, PinnaklEnvironment } from '@pinnakl/core/environment';

const envName: EnvNames = EnvNames.dev;

export const environment: PinnaklEnvironment = {
  envName: envName,
  fileServiceUrl: fileServiceUrls[envName],
  firebaseConfig: firebaseConfigs.prod,
  frontEndErrorServiceUrl: frontEndErrorServiceUrls[envName],
  production: false,
  requestTimeoutPeriod: requestTimeoutPeriods[envName],
  coreApiServerUrl: coreApiServerUrls[envName],
  datastream1Url: datastream1Urls[envName],
  vdrFileAppHost: vdrFileAppHosts[envName],
  appName: AppNames.AUTH,
  authUrl: authUrls[envName]
};
