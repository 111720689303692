import { Component } from '@angular/core';
import { AuthenticatorService } from '../../services/authenticator.service';

@Component({
  selector: 'pnkl-magic-link',
  template: ''
})
export class MagicLinkComponent {
  constructor(private readonly authenticator: AuthenticatorService) {
    this.authenticator.magicLinkNavigated();
  }
}
