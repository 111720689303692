import { Injectable } from '@angular/core';
import { LocalStorageService } from '@pinnakl/core/data-providers';
import { Observable } from 'rxjs';
import { AllowedUserTypeAuthParam } from '@pinnakl/shared/types';

const SOURCE_APP_URL_KEY = 'sourceAppURL';
const LAST_URL_SEARCH = 'lastURLSearch';
const CREDENTIALS_KEY = 'credentials';
const ALLOWED_USER_TYPE_KEY = 'aut';
const AUTH_ERROR_KEY = 'authError';

@Injectable()
export class AuthCacheService {
  get lastURLSearch(): string {
    return this.localStorage.get(LAST_URL_SEARCH) ?? '';
  }

  // Last URL search
  set lastURLSearch(message: string) {
    this.localStorage.set(LAST_URL_SEARCH, message);
  }

  get sourceAppURL(): string {
    return this.localStorage.get(SOURCE_APP_URL_KEY) ?? '';
  }

  // Source App URL
  set sourceAppURL(message: string) {
    this.localStorage.set(SOURCE_APP_URL_KEY, message);
  }

  get sourceAppURL$(): Observable<string | null> {
    return this.localStorage.watch(SOURCE_APP_URL_KEY);
  }

  get credentials(): { username: string; password: string } {
    return this.localStorage.get(CREDENTIALS_KEY) ?? {};
  }

  // Credentials
  set credentials(credentials: object) {
    this.localStorage.set(CREDENTIALS_KEY, credentials);
  }

  get credentials$(): Observable<{ username: string; password: string } | null> {
    return this.localStorage.watch(CREDENTIALS_KEY);
  }

  get allowedUserType(): AllowedUserTypeAuthParam | null {
    return this.localStorage.get(ALLOWED_USER_TYPE_KEY) ?? null;
  }

  // Allowed user Type
  set allowedUserType(type: string) {
    this.localStorage.set(ALLOWED_USER_TYPE_KEY, type);
  }

  get allowedUserType$(): Observable<string | null> {
    return this.localStorage.watch(ALLOWED_USER_TYPE_KEY);
  }

  get errorMessage(): string {
    return this.localStorage.get(AUTH_ERROR_KEY) ?? '';
  }

  // Error message
  set errorMessage(message: string) {
    this.localStorage.set(AUTH_ERROR_KEY, message);
  }

  get errorMessage$(): Observable<string> {
    return this.localStorage.watch(AUTH_ERROR_KEY);
  }

  constructor(private localStorage: LocalStorageService) {}

  clearCredentials() {
    this.localStorage.remove(CREDENTIALS_KEY);
  }

  clearAllowedUserType() {
    this.localStorage.remove(ALLOWED_USER_TYPE_KEY);
  }

  clearErrorMessage() {
    this.localStorage.remove(AUTH_ERROR_KEY);
  }
}
