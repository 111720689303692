import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ENVIRONMENT_SERVICE, EnvironmentService } from '@pinnakl/core/environment';
import { Router } from '@angular/router';
import { PinnaklSpinnerService, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthCacheService } from './auth-cache.service';
import {
  AllowedUserTypeAuthParam,
  ErrorUIToastMessageCategory,
  UserTypes
} from '@pinnakl/shared/types';

declare const require: any;
const packageJson = require('../../../../../../package.json');

@Injectable()
export class AuthenticatorService {
  private ROUTE_AFTER_LOGIN = '/';
  application = 'Desktop';
  baseUrl?: string;
  version$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  errorMessage$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  get authUrls(): Record<
    'login' | 'linkLogin' | 'generateLink' | 'verifyotp' | 'verifyQrOtp',
    string
  > {
    return {
      login: `${this.baseUrl}/account/login`,
      linkLogin: `${this.baseUrl}/account/link/login`,
      generateLink: `${this.baseUrl}/account/link`,
      verifyotp: `${this.baseUrl}/account/verifyotp`,
      verifyQrOtp: `${this.baseUrl}/account/totp/verify`
    };
  }

  get userType(): UserTypes {
    return this.authCacheService.allowedUserType === AllowedUserTypeAuthParam.EXTERNAL
      ? UserTypes.EXTERNAL
      : UserTypes.INTERNAL;
  }

  get sourceAppURL(): string {
    return this.authCacheService.sourceAppURL;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(ENVIRONMENT_SERVICE) readonly environmentService: EnvironmentService,
    private readonly router: Router,
    private readonly toast: PinnaklUIToastMessage,
    private readonly spinner: PinnaklSpinnerService,
    private readonly http: HttpClient,
    private readonly authCacheService: AuthCacheService
  ) {
    this.baseUrl = this.environmentService.get('authUrl');
    this.version$.next(packageJson.version);
    this.errorMessage$.next(this.authCacheService.errorMessage);
    this.authCacheService.clearErrorMessage();
  }

  // The server sends cookies in Set-Cookie header. Without this, Angular will ignore the Set-Cookie header
  login(login: { username: string; password: string }): void {
    this.spinner.spin('auth');
    const user = {
      ...login,
      application: this.application,
      userType: this.userType
      // fingerprint: this.fingerprint,
    };
    const loginUrl = `${this.baseUrl}/account/login` + this.authCacheService.lastURLSearch;
    this.http.post<{ returnUrl: string }>(loginUrl, user, { withCredentials: true }).subscribe({
      next: (res: { returnUrl: string } | null) => {
        if (res.returnUrl.includes('/2fa')) {
          this.authCacheService.credentials = login;
        }
        this.redirectToSource(res.returnUrl);
      },
      error: e => {
        if (e?.status === 401 || e?.status === 403) {
          this.errorMessage$.next('Email/Password combination is incorrect!');
        } else {
          this.errorMessage$.next('Authentication has been reset. Please login.');
          this.redirectToSource();
        }
        this.spinner.stop('auth');
      }
    });
  }

  verifyOtp(otp: string): void {
    this.spinner.spin('auth');
    const params = new URLSearchParams(this.authCacheService.lastURLSearch);
    const token = params.get('token');
    const returnUrlPath = params.get('returnUrl');
    const returnUrl = encodeURIComponent(`${this.baseUrl}` + returnUrlPath);
    const verifyUrl = `${this.authUrls.verifyotp}?token=${token}&otp=${otp}&returnUrl=${returnUrl}`;
    this.http.get<{ returnUrl: string }>(verifyUrl, { withCredentials: true }).subscribe({
      next: ({ returnUrl }) => {
        this.spinner.stop('auth');
        this.authCacheService.clearCredentials();
        this.redirectToSource(returnUrl);
      },
      error: e => {
        this.spinner.stop('auth');
        this.toast.error(
          'Incorrect OTP submitted. Please click "Resend code" to generate a new OTP',
          ErrorUIToastMessageCategory.Error,
          { sticky: true }
        );
      }
    });
  }

  verifyQrOtp(otp: string): void {
    this.spinner.spin('auth');
    const params = new URLSearchParams(this.authCacheService.lastURLSearch);
    const token = params.get('token');
    const returnUrlPath = params.get('returnUrl');
    const returnUrl = encodeURIComponent(`${this.baseUrl}` + returnUrlPath);
    const verifyUrl = `${this.authUrls.verifyQrOtp}?token=${token}&otp=${otp}&returnUrl=${returnUrl}`;
    this.http.get<{ returnUrl: string }>(verifyUrl, { withCredentials: true }).subscribe({
      next: ({ returnUrl }) => {
        this.spinner.stop('auth');
        this.authCacheService.clearCredentials();
        this.redirectToSource(returnUrl);
      },
      error: e => {
        this.spinner.stop('auth');
        this.toast.error('Incorrect OTP submitted', ErrorUIToastMessageCategory.Error, {
          sticky: true
        });
      }
    });
  }

  regenerateMagicLink(email, callback: () => void) {
    this.spinner.spin('auth');
    const endpoint = `${this.authUrls.generateLink}`;
    const returnUrl = `${this.sourceAppURL}${this.ROUTE_AFTER_LOGIN}`;
    return this.http.post<any>(endpoint, { email, usertype: this.userType, returnUrl }).subscribe({
      next: () => {
        this.spinner.stop('auth');
        this.toast.success(
          `A new authentication link has been sent to ${email}. Click on the link to create new password.`
        );
        callback?.();
      },
      error: e => {
        this.spinner.stop('auth');
        this.toast.error('Error while regenerating magic link', ErrorUIToastMessageCategory.Error, {
          sticky: true
        });
      }
    });
  }

  magicLinkNavigated() {
    const isLatestAppVersion = this.isLatestAppVersion();
    const search = new URLSearchParams(this.authCacheService.lastURLSearch);
    const token = search.get('token');
    const returnUrl = search.get('returnUrl');
    const otpChannel = search.get('otpChannel');

    if (!isLatestAppVersion) {
      this.setCurrentAppVersion();
      window.location.assign(window.location.href);
      return;
    }

    if (otpChannel) {
      switch (otpChannel) {
        case 'qr':
        case 'mobile': {
          this.postAccountLinkLogin(token).subscribe({
            next: ({ returnUrl }) => this.redirectToSource(returnUrl),
            error: httpError => this.handleMagicLinkAuthError(httpError)
          });
          return;
        }
      }
    }
    this.postAccountLinkLogin(token).subscribe({
      next: () => this.redirectToSource(returnUrl),
      error: httpError => this.handleMagicLinkAuthError(httpError)
    });
  }

  handleMagicLinkAuthError(httpError): void {
    if (httpError?.error?.passwordResetRequired) {
      this.router.navigateByUrl('/reset-password?isLinkExpired=true');
    } else {
      this.authCacheService.errorMessage = 'Error while post account link login';
      this.redirectToSource();
    }
  }

  redirectToSource(redirectUri?: string) {
    window.location.assign(redirectUri ?? this.sourceAppURL);
  }

  private postAccountLinkLogin(token: string, otp?: string): Observable<any> {
    const url = this.authUrls.linkLogin;
    const body = {
      token,
      ...(otp ? { otp } : {})
    };
    return this.http.post(url, body, { withCredentials: true });
  }

  private setCurrentAppVersion() {
    localStorage.setItem('appVersion', packageJson.version);
  }

  private isLatestAppVersion(): boolean {
    const appVersion = localStorage.getItem('appVersion');
    if (!appVersion) return false;
    return packageJson.version === appVersion;
  }
}
