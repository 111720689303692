import { Injectable } from '@angular/core';
import {
  FeeTerms,
  FeeTermsFromApi,
  InvestorNominee,
  InvestorNomineeFromApi,
  PartnershipAccountFund,
  PartnershipAccountFundFromApi,
  TargetFund
} from '@pinnakl/crm/domain';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class InvestorRelationsService {
  private readonly _crmTargetFundsEndpoint = 'entities/crm_target_funds ';
  private readonly _investorMonthlyStatementEndpoint = 'entities/crm_inverstor_monthly_statement';
  private readonly _investorMonthlyStatementDatesEndpoint =
    'entities/crm_inverstor_monthly_statement_dates';
  private readonly _subEntityEndpoint = 'entities/crm_investor_sub_entity';
  private readonly _fundsEndpoint = 'entities/crm_accounting_funds';
  private readonly _feeTermsEndpoint = 'entities/crm_investor_fee_terms';

  constructor(private readonly wsp: WebServiceProvider) {}

  async getTargetFunds(clientPublicId: string | null): Promise<TargetFund[]> {
    const targetFundClientIdFilters = {
      filters: [
        {
          key: 'clientPublicId',
          type: 'EQ',
          value: [clientPublicId]
        }
      ]
    };
    return this.wsp.getHttp<TargetFund[]>({
      endpoint: this._crmTargetFundsEndpoint,
      optionsParams: {
        fields: ['id', 'targetFund', 'viewOrder'],
        ...(clientPublicId ? targetFundClientIdFilters : {})
      }
    });
  }

  async getOrganizationPerformanceData(payload?: { id?: any; key?: string }): Promise<any> {
    const fundId = payload?.id || null;
    const key = payload?.key || 'targetfundid';
    const endpoint =
      payload?.key !== 'targetfundid' ? 'benchmark_returns' : 'crm_target_fund_returns';

    return this.wsp.getHttp<any>({
      endpoint: `v3/entities/${endpoint}`,
      optionsParams: {
        fields: ['id', key, 'date', 'monthlyreturn'],
        filters: fundId
          ? [
              {
                key,
                type: 'EQ',
                value: [fundId]
              }
            ]
          : []
      }
    });
  }

  async getOrganizationComparisonStats(): Promise<any> {
    return this.wsp.getHttp({
      endpoint: 'v3/entities/benchmark_returns',
      optionsParams: {
        fields: ['id', 'benchmarkid', 'date', 'monthlyreturn']
      }
    });
  }

  // crm_inverstor_monthly_statement_dates
  getOrganizationMonthlyStatement(date): Promise<any> {
    return this.wsp.getHttp({
      endpoint: this._investorMonthlyStatementEndpoint,
      optionsParams: {
        filters: [
          {
            key: 'email',
            type: 'EQ',
            value: ['mrudzik@aegonusa.com']
          },
          {
            key: 'monthenddate',
            type: 'EQ',
            value: [moment(date).format('MM/DD/YYYY').toString()]
          }
        ]
      }
    });
  }

  getOrganizationMonthlyStatementDates(): Promise<any> {
    const filters = [
      {
        key: 'email',
        type: 'EQ',
        value: ['mrudzik@aegonusa.com']
      }
    ];
    return this.wsp.getHttp({
      endpoint: this._investorMonthlyStatementDatesEndpoint,
      optionsParams: {
        filters
      }
    });
  }

  async getNomineesByOrganizationId(investorId?: string): Promise<InvestorNominee[]> {
    const filters = [];
    if (investorId) {
      filters.push({
        key: 'OrganizationId',
        type: 'EQ',
        value: [investorId.toString()]
      });
    }
    const subEntities = await this.wsp.getHttp<InvestorNomineeFromApi[]>({
      endpoint: this._subEntityEndpoint,
      optionsParams: {
        fields: ['*'],
        filters
      }
    });
    return subEntities.map(el => this.formatSubEntities(el));
  }

  formatSubEntities(subEntities: InvestorNomineeFromApi): InvestorNominee {
    return {
      advisorAffiliated: subEntities.advisoraffiliated === 'True',
      entityId: subEntities.entityid,
      erisaEligible: subEntities.erisaeligible === 'True',
      id: +subEntities.id,
      name: subEntities.name,
      notes: subEntities.notes,
      organizationId: +subEntities.organizationid,
      organizationName: subEntities.organizationname,
      fund: subEntities.fund,
      accountingFundId: +subEntities.accountingfundid,
      taxExempt: subEntities.taxexempt === 'True',
      clientId: +subEntities.clientid,
      dateClosed: subEntities.dateclosed ? new Date(subEntities.dateclosed) : null,
      dateFunded: subEntities.datefunded ? new Date(subEntities.datefunded) : null,
      feeTermsId: +subEntities.feetermsid,
      newIssueEligible: subEntities.newissueeligible === 'True',
      terms: subEntities.terms,
      class: subEntities.class,
      nameUI: `${subEntities.name} (${subEntities.fund})`
    };
  }

  // TODO: Moved to organization-details-service.ts - clean after refactor
  async getAllFeeTerms(): Promise<FeeTerms[]> {
    const termsFromApi = await this.wsp.getHttp<FeeTermsFromApi[]>({
      endpoint: this._feeTermsEndpoint,
      optionsParams: {
        fields: ['id', 'terms']
      }
    });

    return termsFromApi.map(item => this.formatFeeTerms(item));
  }

  // TODO: Moved to organization-details-service.ts - clean after refactor
  async getAllAccountingFunds(): Promise<PartnershipAccountFund[]> {
    const funds = await this.wsp.getHttp<PartnershipAccountFundFromApi[]>({
      endpoint: this._fundsEndpoint,
      optionsParams: {
        fields: [
          'id',
          'fund',
          'enddate',
          'fundcode',
          'onshorevsoffshore',
          'portfoliomanager',
          'startdate'
        ]
      }
    });

    return funds.map(item => this.formatPartnershipAccountFund(item));
  }

  formatPartnershipAccountFund(
    partnershipAccountFund: PartnershipAccountFundFromApi
  ): PartnershipAccountFund {
    const { startDate, endDate } = partnershipAccountFund;

    return {
      ...partnershipAccountFund,
      id: +partnershipAccountFund.id,
      endDate: endDate ? new Date(endDate) : endDate,
      fundCode: partnershipAccountFund.fundCode,
      onshoreVsOffshore: partnershipAccountFund.onshoreVsOffshore,
      portfolioManager: partnershipAccountFund.portfolioManager,
      startDate: startDate ? new Date(startDate) : startDate,
      comingled: partnershipAccountFund.comingled,
      totalInvestment: partnershipAccountFund.totalInvestment,
      leverage: partnershipAccountFund.leverage,
      liquidityTerms: partnershipAccountFund.liquidityTerms
    };
  }

  private formatFeeTerms(feeTerms: FeeTermsFromApi): FeeTerms {
    return {
      id: +feeTerms.id,
      terms: feeTerms.terms,
      mgmtFee: feeTerms.mgmtFee,
      incentiveFee: feeTerms.incentiveFee,
      hurdleRate: feeTerms.hurdleRate
    };
  }
}
