import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoopValueAccessorDirective } from '../../directives/noop-value-accessor.directive';
import { FormControl, FormControlStatus, NgControl, ValidationErrors } from '@angular/forms';
import { GetInterpolatedString, InjectNgControl } from '@pinnakl/shared/util-helpers';
import { Observable } from 'rxjs';

const BaseValidationErrors: Record<string, string> = {
  required: 'This field is required',
  minlength: 'Minimum required length is {{requiredLength}}',
  maxlength: 'Maximum length is {{requiredLength}}',
  min: 'Minimum allowed value is {{min}}',
  max: 'Maximum allowed value is {{max}}',
  greaterThan: 'Should be greater than {{value}}',
  pattern: 'Invalid data'
};

@Component({
  selector: 'prime-input-validation',
  standalone: true,
  imports: [CommonModule],
  hostDirectives: [NoopValueAccessorDirective],
  templateUrl: './input-validation.component.html',
  styleUrls: ['./input-validation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputValidationComponent implements OnInit {
  private ngControl: NgControl;
  private formControl?: FormControl;
  @ContentChild('hintContent', { static: false }) hintContent: TemplateRef<null> | null = null;
  @Input() hintLabel?: string;
  @Input() reserveHeight: boolean;
  @Input() showAmountSection: boolean;
  @Input() maxLengthHintValue?: number;
  @Input() customErrors?: Record<string, string>;
  public statusChanges$?: Observable<FormControlStatus>;

  get errorMsg(): string {
    if (this.formControl?.errors) {
      const errors = this.formControl.errors;
      const errorsKeysArray = Object.keys(errors);
      const flattedErrors: Record<string, string | number | boolean> = errorsKeysArray.reduce(
        (flattedErrors, errorKey) => ({
          ...flattedErrors,
          ...errors[errorKey]
        }),
        {}
      );

      if (errorsKeysArray.length) {
        const key = errorsKeysArray[0];

        if (this.formControl.errors[key]) {
          return GetInterpolatedString(
            this.customErrors?.[key] ? this.customErrors[key] : BaseValidationErrors[key],
            flattedErrors
          );
        }
        return '';
      }
      return '';
    }
    return '';
  }

  get dirty(): boolean {
    return !!this.formControl?.dirty;
  }

  get errors(): ValidationErrors | null | undefined {
    return this.formControl?.errors;
  }

  get valueLength(): string {
    return this.formControl?.value?.length?.toString() ?? '';
  }

  constructor(private readonly cdr: ChangeDetectorRef) {
    this.ngControl = InjectNgControl();
    this.reserveHeight = true;
    this.showAmountSection = false;
  }

  ngOnInit(): void {
    this.formControl = this.ngControl.control as FormControl;
    this.statusChanges$ = this.formControl.statusChanges;
  }
}
