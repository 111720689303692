import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({ template: '', standalone: true })
export class InputPasswordPublicApiComponent {
  /**
   * Defines a string that labels the input for accessibility.
   * @group Props
   */
  @Input() ariaLabel: string | undefined;
  /**
   * Specifies one or more IDs in the DOM that labels the input field.
   * @group Props
   */
  @Input() ariaLabelledBy: string | undefined;
  /**
   * Label of the input for accessibility.
   * @group Props
   */
  @Input() label: string | undefined;
  /**
   * Indicates whether the component is disabled or not.
   * @group Props
   */
  @Input() disabled: boolean | undefined;
  /**
   * Text to prompt password entry. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  @Input() promptLabel: string | undefined;
  /**
   * Regex value for medium regex.
   * @group Props
   */
  @Input() mediumRegex =
    '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})';
  /**
   * Regex value for strong regex.
   * @group Props
   */
  @Input() strongRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})';
  /**
   * Text for a weak password. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  @Input() weakLabel: string | undefined;
  /**
   * Text for a medium password. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  @Input() mediumLabel: string | undefined;
  /**
   * specifies the maximum number of characters allowed in the input element.
   * @group Props
   */
  @Input() maxLength: number | undefined;
  /**
   * Text for a strong password. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  @Input() strongLabel: string | undefined;
  /**
   * Identifier of the accessible input element.
   * @group Props
   */
  @Input() inputId: string | undefined;
  /**
   * Whether to show the strength indicator or not.
   * @group Props
   */
  @Input() feedback = true;
  /**
   * Id of the element or "body" for document where the overlay should be appended to.
   * @group Props
   */
  @Input() appendTo: HTMLElement | ElementRef | TemplateRef<any> | string | null | undefined | any;
  /**
   * Whether to show an icon to display the password as plain text.
   * @group Props
   */
  @Input() toggleMask: boolean | undefined;
  /**
   * Style class of the input field.
   * @group Props
   */
  @Input() inputStyleClass: string | undefined;
  /**
   * Style class of the element.
   * @group Props
   */
  @Input() styleClass: string | undefined;
  /**
   * Inline style of the component.
   * @group Props
   */
  @Input() style: { [klass: string]: any } | null | undefined;
  /**
   * Inline style of the input field.
   * @group Props
   */
  @Input() inputStyle: { [klass: string]: any } | null | undefined;
  /**
   * Transition options of the show animation.
   * @group Props
   */
  @Input() showTransitionOptions = '.12s cubic-bezier(0, 0, 0.2, 1)';
  /**
   * Transition options of the hide animation.
   * @group Props
   */
  @Input() hideTransitionOptions = '.1s linear';
  /**
   * Specify automated assistance in filling out password by browser.
   * @group Props
   */
  @Input() autocomplete: string | undefined;
  /**
   * Advisory information to display on input.
   * @group Props
   */
  @Input() placeholder: string | undefined;
  /**
   * When enabled, a clear icon is displayed to clear the value.
   * @group Props
   */
  @Input() showClear = false;
  /**
   * Callback to invoke when the component receives focus.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  @Output() onFocus: EventEmitter<Event> = new EventEmitter<Event>();
  /**
   * Callback to invoke when the component loses focus.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  @Output() onBlur: EventEmitter<Event> = new EventEmitter<Event>();
  /**
   * Callback to invoke when clear button is clicked.
   * @group Emits
   */
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
}
