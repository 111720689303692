import { EnvNames } from '@pinnakl/core/environment';

const crmAppUrls = {
  local: 'https://localhost:53591/',
  dev: 'https://appsvc-externalservices-dev.azurewebsites.net',
  staging: 'https://appsvc-externalservices-dev.azurewebsites.net',
  prod: 'https://external-services-prod.pinnakl.com/'
};

export const fileServiceUrls = {
  local: 'https://localhost:53591/File/',
  dev: 'https://appsvc-externalservices-dev.azurewebsites.net/File/',
  staging: 'https://appsvc-externalservices-dev.azurewebsites.net/File/',
  prod: 'https://external-services-prod.pinnakl.com/File/'
};

export const firebaseConfigs = {
  prod: {
    messagingSenderId: '477103941646'
  },
  staging: {
    messagingSenderId: '920036889346'
  }
};

const FRONT_END_ERROR = 'FrontEndError/Post';

export const frontEndErrorServiceUrls = {
  local: `${crmAppUrls.local}${FRONT_END_ERROR}`,
  dev: `${crmAppUrls.dev}${FRONT_END_ERROR}`,
  staging: `${crmAppUrls.staging}${FRONT_END_ERROR}`,
  prod: `${crmAppUrls.prod}${FRONT_END_ERROR}`
};

export const requestTimeoutPeriods = {
  local: 3000000,
  dev: 300000,
  staging: 300000,
  prod: 30000
};

export const coreApiServerUrls = {
  local: 'https://localhost:5149',
  dev: 'https://apicore-dev.pinnakl.com',
  staging: 'https://apicore-prod-002.pinnakl.com',
  prod: 'https://apicore-prod-001.pinnakl.com'
};

export const datastream1Urls = {
  local: 'https://localhost:44393/api/',
  dev: 'https://appsvc-sse-dev.azurewebsites.net/api/',
  staging: 'https://appsvc-sse-stage.azurewebsites.net/api/',
  prod: 'https://sse.pinnakl.com/api/'
};

export const authUrls = {
  local: 'https://localhost:5149',
  dev: 'https://apicore-dev.pinnakl.com',
  prod: 'https://apicore-prod-001.pinnakl.com'
};

export const vdrFileAppHosts = {
  [EnvNames.local]: 'https://ir.dev.pinnakl.com',
  [EnvNames.dev]: 'https://ir.dev.pinnakl.com',
  [EnvNames.prod]: 'https://ir.pinnakl.com'
};
