import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { BehaviorSubject, Observable } from 'rxjs';
import { StylesBuilderClass } from '@pinnakl/shared/util-helpers';
import { buttonClasses } from './prime-button-classes-map';
import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { ButtonPublicApiComponent } from './button-public-api.component';
import { RippleModule } from 'primeng/ripple';
import {
  ButtonConfig,
  PrimeButtonSizeType,
  PrimeButtonSizeTypeDefault
} from './prime-button-config.builder';
import { AutoFocusModule } from 'primeng/autofocus';

@Component({
  selector: 'prime-button',
  templateUrl: './prime-button.component.html',
  styleUrls: ['./prime-button.component.scss'],
  standalone: true,
  imports: [ButtonModule, AsyncPipe, RippleModule, NgIf, NgTemplateOutlet, AutoFocusModule],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimeButtonComponent extends ButtonPublicApiComponent implements OnInit, OnChanges {
  private classesFieldsList: string[];
  private readonly combinedClassNames$$: BehaviorSubject<string>;
  // Custom btn size attribute
  @Input() size: PrimeButtonSizeType;
  @Input() iconNearLabel = false;
  public readonly classes$: Observable<string>;
  @ContentChild('iconTemplate', { static: false }) iconTemplate: TemplateRef<null> | null = null;
  @ContentChild('contentTemplate', { static: false }) contentTemplate: TemplateRef<null> | null = null;

  /**
   * Experimental feature
   * */
  @Input() set buttonConfig(buttonConfig: ButtonConfig) {
    this.label = buttonConfig.label;
    this.size = buttonConfig.size;

    this.rebuildClassesString();

    this.cdr.markForCheck();
  }

  constructor(private readonly cdr: ChangeDetectorRef) {
    super();
    this.size = PrimeButtonSizeTypeDefault;
    this.combinedClassNames$$ = new BehaviorSubject<string>('');
    this.classes$ = this.combinedClassNames$$.asObservable();
    this.classesFieldsList = ['size', 'styleClass'];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.classesFieldsList.some(field => changes[field])) {
      this.rebuildClassesString();
    }
  }

  ngOnInit(): void {
    this.rebuildClassesString();
  }

  rebuildClassesString(): void {
    this.combinedClassNames$$.next(
      this.classesFieldsList.reduce<StylesBuilderClass>(
        (builder, inputParamName) =>
          buttonClasses[inputParamName] !== ''
            ? builder.addClass(buttonClasses[this[inputParamName]])
            : builder,
        new StylesBuilderClass()
      ).classesString +
        ' ' +
        (this.styleClass ?? '') +
        ' prime-button ' +
        `${this.iconNearLabel || this.iconTemplate || this.icon ? ' icon-near-label ' : ' '}`
    );
  }
}
