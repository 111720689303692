import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoopValueAccessorDirective } from '../../directives';
import {
  InjectNgControl,
  ListenFormControlStatusChangesComponent,
  ResetFormControl,
  SubscribeToStatusChanges
} from '@pinnakl/shared/util-helpers';
import { AbstractControl, FormControl, NgControl } from '@angular/forms';

@Component({
  selector: 'prime-label-content',
  standalone: true,
  imports: [CommonModule],
  hostDirectives: [NoopValueAccessorDirective],
  templateUrl: './label-content.component.html',
  styleUrls: ['./label-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelContentComponent implements ListenFormControlStatusChangesComponent, OnInit {
  @Input() disabled?: boolean;
  @Input({ required: true }) label: string;
  @Input() styleClass = '';

  public ngControl: NgControl;
  public formControl?: FormControl;
  public readonly destroyRef = inject(DestroyRef);

  get controlRequired(): boolean {
    return !!this.formControl?.validator?.({} as AbstractControl)?.['required'];
  }

  get controlDisabled(): boolean {
    return !!(this.formControl?.disabled || this.disabled);
  }

  get controlInvalid(): boolean {
    return !!(this.formControl?.invalid && this.formControl?.dirty);
  }

  constructor(public readonly cdr: ChangeDetectorRef) {
    this.ngControl = InjectNgControl();
    this.label = '';
  }

  ngOnInit(): void {
    ResetFormControl(this);
    SubscribeToStatusChanges(this);
  }
}
