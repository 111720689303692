import { Injectable } from '@angular/core';
import {
  CustomAttribute,
  CustomAttributeFeature,
  CustomAttributeFromApi,
  CustomAttributeListOption,
  CustomAttributeListOptionFromApi,
  CustomAttributeMappingTable,
  CustomAttributeValue,
  CustomAttributeValueFromApi
} from '@pinnakl/shared/types';
import { WebServiceProvider } from '@pinnakl/core/web-services';

import * as _ from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment';

@Injectable()
export class CustomAttributesService {
  private readonly _securityCustomAttributesEndpoint = 'entities/security_custom_attributes';
  private readonly _securityCustomAttributeListOptionsEndpoint =
    'entities/security_custom_attribute_list_options';
  private readonly _securityCustomAttributeValuesEndpoint =
    'entities/security_custom_attribute_values';

  constructor(private readonly wsp: WebServiceProvider) {}

  async deleteCustomAttribute(id: number): Promise<void> {
    await this.wsp.deleteHttp({
      endpoint: `${this._securityCustomAttributesEndpoint}/${id}`
    });
  }

  async deleteCustomAttributeListOption(id: number): Promise<void> {
    await this.wsp.deleteHttp({
      endpoint: `${this._securityCustomAttributeListOptionsEndpoint}/${id}`
    });
  }

  async deleteCustomAttributeValue(id: number): Promise<CustomAttributeValue> {
    const entities = await this.wsp.deleteHttp<CustomAttributeValueFromApi[]>({
      endpoint: `${this._securityCustomAttributeValuesEndpoint}/${id}`
    });
    return this.formatCustomAttributeValue(entities[0]);
  }

  async getCustomAttributes(feature?: string, withListOptions = true): Promise<CustomAttribute[]> {
    if (!feature) {
      throw 'Please provide features';
    }
    const customAttributes = await this.getCustomAttributesWithoutListOptions(feature);

    if (!withListOptions) return customAttributes;

    const promises = _(customAttributes)
      .filter({ type: 'List' })
      .map(attribute =>
        this.getCustomAttributeListOptions(attribute.id).then(
          listOptions => (attribute.listOptions = _.sortBy(listOptions, ['viewOrder']))
        )
      )
      .value();

    const result = await Promise.all([customAttributes, Promise.all(promises)]);

    const [customAttrs] = result;
    return customAttrs;
  }

  async getCustomAttributesWithoutListOptions(feature: string): Promise<CustomAttribute[]> {
    const entities = await this.wsp.getHttp<CustomAttributeFromApi[]>({
      endpoint: this._securityCustomAttributesEndpoint,
      optionsParams: feature
        ? {
            filters: [
              {
                key: 'Feature',
                type: 'EQ',
                value: [feature]
              }
            ]
          }
        : {}
    });

    return entities.map(this.formatCustomAttribute);
  }

  async getCustomAttributeValuesForAttribute(attributeId: number): Promise<CustomAttributeValue[]> {
    const entities = await this.wsp.getHttp<CustomAttributeValueFromApi[]>({
      endpoint: this._securityCustomAttributeValuesEndpoint,
      optionsParams: {
        fields: ['CustomAttributeId', 'Id', 'SecurityId', 'Type', 'Value'],
        filters: [
          {
            key: 'CustomAttributeId',
            type: 'EQ',
            value: [attributeId.toString()]
          }
        ]
      }
    });

    return entities.map(this.formatCustomAttributeValue);
  }

  async getCustomAttributeValuesForFeature(
    id: number,
    feature: 'Investor' | 'Security' | 'Contact'
  ): Promise<CustomAttributeValue[]> {
    const entities = await this.wsp.getHttp<CustomAttributeValueFromApi[]>({
      endpoint: this._securityCustomAttributeValuesEndpoint,
      optionsParams: {
        fields: ['CustomAttributeId', 'Id', `${feature}Id`, 'Type', 'Value'],
        filters: [
          {
            key: `${feature}Id`,
            type: 'EQ',
            value: [id.toString()]
          }
        ]
      }
    });

    return entities.map(this.formatCustomAttributeValue);
  }

  async postCustomAttribute(
    entityToSave: CustomAttribute,
    feature: CustomAttributeFeature,
    mappingTable: CustomAttributeMappingTable
  ): Promise<CustomAttribute> {
    const entity = await this.wsp.postHttp<CustomAttributeFromApi>({
      endpoint: this._securityCustomAttributesEndpoint,
      body: this.getCustomAttributeForServiceRequest(entityToSave, feature, mappingTable)
    });
    return this.formatCustomAttribute(entity);
  }

  async postCustomAttributeListOption(
    entityToSave: CustomAttributeListOption
  ): Promise<CustomAttributeListOption> {
    const entity = await this.wsp.postHttp<CustomAttributeListOptionFromApi>({
      endpoint: this._securityCustomAttributeListOptionsEndpoint,
      body: this.getCustomAttributeListOptionForServiceRequest(entityToSave)
    });
    return this.formatCustomAttributeListOption(entity);
  }

  async postCustomAttributeValue(
    entitiesToSave: CustomAttributeValue[],
    feature?: CustomAttributeFeature
  ): Promise<CustomAttributeValue[]> {
    const body = entitiesToSave.map(entityToSave =>
      this.getCustomAttributeValueForServiceRequest(
        entityToSave,
        feature === CustomAttributeFeature.ORGANIZATION || !feature ? 'Investor' : feature
      )
    );

    const entities = await this.wsp.postHttp<CustomAttributeValueFromApi>({
      endpoint: this._securityCustomAttributeValuesEndpoint,
      body
    });
    return entities.fields
      ? entities.fields.map(entity => ({
          ...this.formatCustomAttributeValue(entity),
          action: entities.action
        }))
      : [
          {
            ...this.formatCustomAttributeValue(entities),
            action: 'POST'
          }
        ];
  }

  async putCustomAttribute(
    entityToSave: CustomAttribute,
    feature: CustomAttributeFeature,
    mappingTable: CustomAttributeMappingTable
  ): Promise<CustomAttribute> {
    const entity = await this.wsp.putHttp<CustomAttributeFromApi>({
      endpoint: this._securityCustomAttributesEndpoint,
      body: this.getCustomAttributeForServiceRequest(entityToSave, feature, mappingTable)
    });

    return this.formatCustomAttribute(entity);
  }

  async putCustomAttributeListOption(
    entityToSave: CustomAttributeListOption
  ): Promise<CustomAttributeListOption> {
    const entity = await this.wsp.putHttp<CustomAttributeListOptionFromApi>({
      endpoint: this._securityCustomAttributeListOptionsEndpoint,
      body: this.getCustomAttributeListOptionForServiceRequest(entityToSave)
    });
    return this.formatCustomAttributeListOption(entity);
  }

  async putCustomAttributeValue(
    entityToSave: CustomAttributeValue,
    feature?: CustomAttributeFeature
  ): Promise<CustomAttributeValue> {
    const entity = await this.wsp.putHttp<CustomAttributeValueFromApi>({
      endpoint: this._securityCustomAttributeValuesEndpoint,
      body: this.getCustomAttributeValueForServiceRequest(
        entityToSave,
        feature === CustomAttributeFeature.ORGANIZATION || !feature ? 'Investor' : feature
      )
    });

    return this.formatCustomAttributeValue(entity);
  }

  public formatCustomAttribute(entity: CustomAttributeFromApi): CustomAttribute {
    const id = parseInt(entity.id, 10);
    if (isNaN(id)) {
      throw new Error('Invalid id');
    }
    return new CustomAttribute(id, entity.name, entity.type, entity.ismandatory === 'True');
  }

  public formatCustomAttributeListOption(
    entity: CustomAttributeListOptionFromApi
  ): CustomAttributeListOption {
    const id = parseInt(entity.id, 10);
    const viewOrder = parseInt(entity.vieworder, 10);
    const customAttributeId = parseInt(entity.customattributeid, 10);

    if (isNaN(customAttributeId)) {
      throw new Error('Invalid custom attribute id');
    }
    if (isNaN(id)) {
      throw new Error('Invalid id');
    }
    if (isNaN(viewOrder)) {
      throw new Error('Invalid view order');
    }

    return new CustomAttributeListOption(customAttributeId, id, entity.listoption, viewOrder);
  }

  private formatCustomAttributeValue(entity: CustomAttributeValueFromApi): CustomAttributeValue {
    const customAttributeId = parseInt(entity.customattributeid, 10),
      securityId = parseInt(entity.securityid ?? '', 10),
      type = entity.type,
      value = entity.value;
    let parsedValue;
    let valueToProcess: unknown;

    switch (type) {
      case 'Number':
        valueToProcess = parseFloat(value);
        parsedValue = !isNaN(valueToProcess as number) ? valueToProcess : null;
        break;
      case 'Date':
        valueToProcess = moment(value, 'MM/DD/YYYY');
        parsedValue = (valueToProcess as Moment).isValid()
          ? (valueToProcess as Moment).toDate()
          : null;
        break;
      case 'Checkbox':
        parsedValue = value === '1';
        break;
      default:
        parsedValue = value;
    }

    if (isNaN(customAttributeId)) {
      throw new Error('Invalid custom attribute id');
    }

    return new CustomAttributeValue({
      customAttributeId: !isNaN(customAttributeId) ? customAttributeId : undefined,
      id: entity.id,
      securityId: !isNaN(securityId) ? securityId : undefined,
      type: type,
      value: parsedValue
    });
  }

  private async getCustomAttributeListOptions(
    customAttributeId: number
  ): Promise<CustomAttributeListOption[]> {
    const entities = await this.wsp.getHttp<CustomAttributeListOptionFromApi[]>({
      endpoint: this._securityCustomAttributeListOptionsEndpoint,
      optionsParams: {
        fields: ['CustomAttributeId', 'Id', 'ListOption', 'ViewOrder'],
        filters: [
          {
            key: 'CustomAttributeId',
            type: 'EQ',
            value: [customAttributeId.toString()]
          }
        ]
      }
    });

    return entities.map(this.formatCustomAttributeListOption);
  }

  private getCustomAttributeForServiceRequest(
    entity: CustomAttribute,
    feature: CustomAttributeFeature,
    mappingTable: CustomAttributeMappingTable
  ): CustomAttributeFromApi {
    const entityForApi = {} as CustomAttributeFromApi,
      { id, name, type, isMandatory } = entity;
    if (id !== undefined) {
      entityForApi.id = id.toString();
    }
    if (name !== undefined) {
      entityForApi.name = name;
    }
    if (type !== undefined) {
      entityForApi.type = type;
    }
    entityForApi.feature = feature;
    entityForApi.mappingTable = mappingTable;
    entityForApi.ismandatory = +(isMandatory ?? false) + '';
    return entityForApi;
  }

  private getCustomAttributeListOptionForServiceRequest(
    entity: CustomAttributeListOption
  ): CustomAttributeListOptionFromApi {
    const entityForApi = {} as CustomAttributeListOptionFromApi,
      { customAttributeId, id, listOption, viewOrder } = entity;
    if (customAttributeId !== undefined) {
      entityForApi.customattributeid = customAttributeId.toString();
    }
    if (id !== undefined) {
      entityForApi.id = id.toString();
    }
    if (listOption !== undefined) {
      entityForApi.listoption = listOption;
    }
    if (viewOrder !== undefined) {
      entityForApi.vieworder = viewOrder.toString();
    }
    return entityForApi;
  }

  private getCustomAttributeValueForServiceRequest(
    entity: CustomAttributeValue,
    feature: 'Investor' | 'Security' | 'Contact'
  ): CustomAttributeValueFromApi {
    const entityForApi = {} as CustomAttributeValueFromApi,
      { customAttributeId, id, securityId, type, value } = entity;
    if (customAttributeId !== undefined) {
      entityForApi.customattributeid = customAttributeId.toString();
    }
    if (id !== undefined) {
      entityForApi.id = id.toString();
    }
    if (securityId !== undefined) {
      entityForApi.securityid = securityId.toString();
    }
    if (value !== undefined) {
      switch (type) {
        case 'Date':
          entityForApi.value = moment(value).format('MM/DD/YYYY');
          break;
        case 'Number':
          entityForApi.value = value.toString();
          break;
        case 'Checkbox':
          entityForApi.value = value ? '1' : '0';
          break;
        default:
          entityForApi.value = typeof value === 'boolean' ? (+value).toString() : <string>value;
      }
    }
    entityForApi[`${feature?.toLowerCase()}id`] = entity[`${feature?.toLowerCase()}Id`]?.toString();
    return entityForApi;
  }
}
