<div class="login-form">
  <ng-container *ngIf="isLinkExpired && !generatedLinkSent">
    <div class="error-message">
      <i class="icon icon-pinnakl-task-status-error"></i>
      <span> Your invitation link has been already used or expired. </span>
    </div>
  </ng-container>
  <ng-container *ngIf="generatedLinkSent">
    <div class="success-message">
      <i class="icon icon-pinnakl-ok-circle"></i>
      <span>
        A password reset link has been sent to your email.<br />
        Click on the link to reset your password.
      </span>
    </div>
  </ng-container>

  <div class="text-lg font-semibold">Please provide your email to reset your password</div>

  <form
    (keydown.enter)="forgotPasswordForm.valid && forgotPassword()"
    [class.ng-submitted]="submitted"
    [formGroup]="forgotPasswordForm"
  >
    <div class="mt-4">
      <prime-input-container>
        <ng-template #labelContent>
          <prime-label-content formControlName="email" label="Email" />
        </ng-template>
        <ng-template #inputContent>
          <prime-text-input class="flex w-100" formControlName="email" />
        </ng-template>
        <ng-template #validationContent>
          <prime-input-validation
            [customErrors]="{
              pattern: 'Enter a valid email address'
            }"
            formControlName="email"
          />
        </ng-template>
      </prime-input-container>
    </div>

    <div class="d-flex justify-content-between align-items-start mt-2">
      <div (click)="backToLogin()" class="back-to-login-link">
        <a>Back to login</a>
      </div>
      <prime-button
        (onClick)="forgotPassword()"
        [rounded]="true"
        [style]="{ 'letter-spacing': '1px' }"
        label="Send"
        severity="primary"
      ></prime-button>
    </div>
  </form>
</div>
