import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { environment } from '../environments';
import { CoreMaintenanceModule } from '@pinnakl/core/maintenance';
import { EnvironmentModule } from '@pinnakl/core/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticatorModule } from './auth/authenticator.module';
import { AppRoutingModule } from './app-routing.module';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { UiToastMessageService } from '@pinnakl/shared/ui/prime';
import { PinnaklGlobalSpinnerComponent } from '@pinnakl/shared/base-components';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    EnvironmentModule.forRoot({ config: environment }),
    CoreMaintenanceModule.register({
      coreApiServerUrl: environment.coreApiServerUrl
    }),
    AppRoutingModule,
    AuthenticatorModule,
    ToastModule,
    PinnaklGlobalSpinnerComponent
  ],
  providers: [
    MessageService,
    {
      provide: PinnaklUIToastMessage,
      useClass: UiToastMessageService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
