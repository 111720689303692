<span
  *ngFor="let holder of placeholders; index as i"
  [class.code-hidden]="isCodeHidden"
  class="input-wrapper"
>
  <input
    #input
    (click)="onClick($event)"
    (input)="onInput($event, i)"
    (keydown)="onKeydown($event, i)"
    (paste)="onPaste($event, i)"
    [disabled]="disabled"
    [type]="inputType"
    autocomplete="one-time-code"
    class="digit-input"
  />
  <input disabled id="underscore" type="text" value="_"/>
</span>
