<div
  [ngClass]="{
    'size-sm': size === 'sm',
  }"
  class="input-wrap {{ (iconClass || iconTemplate) ? 'p-input-icon-' + (iconPosition ?? 'left') : '' }}"
>
  <i *ngIf="iconClass" class="{{ iconClass }}"></i>
  <ng-container *ngIf="iconTemplate">
    <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
  </ng-container>
  <input
    (blur)="blurEvent.emit($event)"
    (focus)="focusEvent.emit($event)"
    [formControl]="$any(formControl)"
    [ngClass]="inputClassesObj"
    [pKeyFilter]="keyFilter"
    [placeholder]="placeholder"
    [type]="type"
    pInputText
  />
</div>
