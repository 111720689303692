<ng-container [ngSwitch]="otpChannel">
  <div class="welcome-block mt-5">
    <span class="welcome-text mt-5">2 factor authentication</span>
    <p *ngSwitchCase="'MOBILE'" class="welcome-sub-text">
      Please enter the code received via Email/SMS below
    </p>
    <p *ngSwitchCase="'QR'" class="welcome-sub-text">
      Please enter the code Pinnakl registration below
    </p>
    <p *ngSwitchCase="'EMAIL'" class="welcome-sub-text">
      Please enter the code received via Email/SMS below
    </p>
  </div>
</ng-container>
<pinnakl-code-input
  (codeChanged)="codeChanged($event)"
  (codeCompleted)="codeCompleted()"
></pinnakl-code-input>
<button (click)="loginOtp()" [disabled]="!otpInputCompleted" class="two-fa-go-btn">
  <span>{{ otpInputCompleted ? 'Go!' : otpInputDigitsLeft + ' digits left' }}</span>
</button>

<a
  (click)="resendCode()"
  *ngIf="(credentials$ | async) && (otpChannel === 'EMAIL' || otpChannel === 'MOBILE')"
  class="link-btn"
><span>Resend code?</span></a
>
<a (click)="backToLogin()" *ngIf="sourceAppURL$ | async" class="link-btn mt-4"
><span>Back to Login page</span></a
>
