import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UploadProgressService {
  private progressMap = new BehaviorSubject<Map<string, number>>(new Map());
  progressMap$: Observable<Map<string, number>> = this.progressMap.asObservable().pipe(shareReplay(1));

  updateProgressMap(uploadId: string, progress: number):void {
    const value = this.progressMap.getValue();
    progress === 100 ? value.delete(uploadId) : value.set(uploadId, progress);
    this.progressMap.next(value);
  }
}
