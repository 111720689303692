import { Component } from '@angular/core';
import { AuthCacheService } from './auth/services/auth-cache.service';

@Component({
  selector: 'pinnakl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private readonly authCacheService: AuthCacheService) {
    this.processUrl();
  }

  processUrl(): void {
    const { search } = window.location;
    if (search) {
      this.cacheUrlSearchState(search);
    } else {
      this.assignCachePropertiesFromLastURLSearch();
    }
  }

  cacheUrlSearchState(search: string): void {
    this.authCacheService.lastURLSearch = search;
    const { origin, pathname } = new URL(window.location.href);
    window.location.assign(`${origin}${pathname}`);
  }

  assignCachePropertiesFromLastURLSearch(): void {
    if (window.location.pathname.includes('/mlink')) {
      const state = new URLSearchParams(this.authCacheService.lastURLSearch);
      const redirectUri = state.get('returnUrl');
      if (redirectUri) {
        this.authCacheService.sourceAppURL = redirectUri;
      }
    } else {
      const state = new URLSearchParams(this.authCacheService.lastURLSearch);
      const returnUrl = state.get('returnUrl');
      if (returnUrl) {
        const returnUrlState = new URLSearchParams(returnUrl);
        const redirectUri = returnUrlState.get('redirect_uri');
        const allowedUserType = returnUrlState.get('aut');
        if (redirectUri) {
          this.authCacheService.sourceAppURL = redirectUri;
        }
        if (allowedUserType) {
          this.authCacheService.allowedUserType = allowedUserType;
        }
      }
    }
  }
}
