import { Injectable } from '@angular/core';
import { PresetModel } from '@pinnakl/shared/types';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { ColDef } from 'ag-grid-community';
import { cloneDeep } from 'lodash';

export enum PinnaklModuleNames {
  OMS = 'OMS',
  EMS = 'EMS',
  Modeling = 'Modeling',
  CRM = 'CRM',
  PA = 'Performance Attribution'
}

export const EMSHomePresetConfigName = 'ems_home_preset';
export const EMSTableColumnsConfig = 'ems_columns_config';
export const ModelingTableColumnsConfig = 'modeling_columns_config';
export const OMSTableColumnsConfig = 'oms_columns_config_v2';
export const filterPresetConfigName = 'preset';

@Injectable({
  providedIn: 'root'
})
export class PresetsService {
  private readonly _presetsEndpoint = 'entities/user_module_configs';

  constructor(private readonly wsp: WebServiceProvider) {}

  public createPreset(preset: PresetModel): Promise<any> {
    return this.wsp.postHttp<any>({
      endpoint: this._presetsEndpoint,
      body: preset
    });
  }

  public updatePreset(preset: PresetModel): Promise<any> {
    return this.wsp.putHttp<any>({
      endpoint: this._presetsEndpoint,
      body: preset
    });
  }

  public deletePreset(presetId: number): Promise<PresetModel> {
    return this.wsp.deleteHttp<PresetModel>({
      endpoint: `${this._presetsEndpoint}/${presetId}`
    });
  }

  public getPresets(userId: number, configName: string, module: string): Promise<PresetModel[]> {
    return this.wsp.getHttp<PresetModel[]>({
      endpoint: this._presetsEndpoint,
      optionsParams: {
        fields: ['id', 'userid', 'module', 'configname', 'configvalue'],
        filters: [
          {
            key: 'userid',
            type: 'EQ',
            value: [userId.toString()]
          },
          {
            key: 'configname',
            type: 'EQ',
            value: [configName]
          },
          {
            key: 'module',
            type: 'EQ',
            value: [module]
          }
        ]
      }
    });
  }

  // Method received preset from server side, compare it with default column settings,
  // and replace values with current saved formatting of columns.

  public transformColumnsConfiguration(
    config: string,
    defaultConfig: { [key: string]: ColDef }
  ): ColDef[] {
    if (config) {
      const parsedConfig = JSON.parse(config);
      const valuesDefaulConfig = Object.values(defaultConfig);
      return parsedConfig
        .map(item => {
          let selectedColm = valuesDefaulConfig.find(
            config => config.field?.toLowerCase() === item.field.toLowerCase()
          );
          if (selectedColm) {
            // default columns can be without default width
            // to avoid issues with width undefined, we set width only if it's present
            return {
              ...selectedColm,
              width: item?.width ?? undefined
            };
          }

          switch (item.headerName) {
            case '% Done':
              selectedColm = { ...defaultConfig['done'] };
              break;
            case 'Filled Prc':
              selectedColm = { ...defaultConfig['filledPrc'] };
              break;
            default:
              selectedColm = { ...item };
          }

          return selectedColm;
        })
        .filter(item => {
          return item !== undefined;
        });
    }
    return cloneDeep(Object.values(defaultConfig));
  }

  /**
   * Can be 2 options:
   * 1. If default columns does not have width
   * 2. If default columns have width
   * @param currentStateCols
   * @param compareWithDefaultConfig
   * @param defaultConfig
   */
  public checkIfGridWasResized(
    currentStateCols: ColDef[],
    compareWithDefaultConfig = false,
    defaultConfig?: {
      [key: string]: ColDef;
    }
  ): boolean {
    if (compareWithDefaultConfig) {
      return currentStateCols.some(item => {
        const split = item.field?.split('.');
        return item.width !== defaultConfig[split[split.length - 1]]?.width;
      });
    } else {
      return currentStateCols.some(item => {
        return item.width;
      });
    }
  }
}
