<div [ngClass]="{ 'login-external-type': isExternalUser }" class="login-container">
  <div class="decor-left"></div>
  <div class="login-column h-100 d-flex flex-column justify-content-stretch align-items-center">
    <img alt="pinnakl-logo" class="logo img-responsive mx-auto" src="/assets/images/big-logo.png"/>
    <div class="w-100">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="decor-right"></div>
</div>
