import { Component, inject } from '@angular/core';
import { PinnaklSpinnerService } from '@pinnakl/shared/util-providers';
import { AsyncPipe } from '@angular/common';

@Component({
  standalone: true,
  selector: 'pinnakl-spinner',
  templateUrl: 'global-spinner.component.html',
  imports: [AsyncPipe],
  styleUrls: ['global-spinner.component.scss']
})
export class PinnaklGlobalSpinnerComponent {
  visible$ = inject(PinnaklSpinnerService).isSpinning$;
}
