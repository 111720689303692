<span
  [ngClass]="[
    controlDisabled ? 'disabled' : '',
    controlRequired ? 'label-required' : '',
    controlInvalid ? 'ng-invalid ng-dirty' : '',
    styleClass
  ]"
  class="label-text"
>
  {{ label }}
</span>
