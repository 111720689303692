import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ComponentFills, ComponentSizes } from '@pinnakl/shared/types';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule, KeyFilterPattern } from 'primeng/keyfilter';
import { NoopValueAccessorDirective } from '../../directives';
import { CommonModule } from '@angular/common';
import { ControlInjectedInputTextComponent } from './control-injected-input-text.component';
import { SharedModule } from 'primeng/api';

export type InputTextSize = ComponentSizes;
export type InputTextFill = ComponentFills;
const DefaultInputTextSize = 'md';
const imports = [CommonModule, InputTextModule, KeyFilterModule, ReactiveFormsModule];

@UntilDestroy()
@Component({
  imports: [imports, SharedModule],
  standalone: true,
  selector: 'prime-text-input',
  templateUrl: './prime-input-text.component.html',
  styleUrls: ['./prime-input-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [NoopValueAccessorDirective]
})
export class PrimeInputTextComponent extends ControlInjectedInputTextComponent {
  // custom `fill` input for theming, variant is not being utilized
  @Input() fill: InputTextFill = 'outlined';
  @Input() size: InputTextSize;
  @Input() rounded: boolean;
  @Input() required?: boolean;
  @Input() iconClass?: string;
  @Input() placeholder?: string;
  @Input() keyFilter: RegExp | KeyFilterPattern;
  @Input() iconPosition?: 'left' | 'right';
  @Input() type?: 'search' | 'text' | 'password' | 'email' | 'number' | 'tel' | 'url' = 'text';
  @Input() inputClasses: Record<string, boolean>;
  @Output() focusEvent = new EventEmitter<FocusEvent>();
  @Output() blurEvent = new EventEmitter<FocusEvent>();
  @ContentChild('iconTemplate', { static: false }) iconTemplate: TemplateRef<null> | null = null;
  @ContentChild('validationContent', { static: false })
  validationContent: TemplateRef<null> | null = null;
  @HostBinding('class') get classes(): string {
    return `fill-${this.fill} prime-input`;
  }
  get inputClassesObj(): Record<string, boolean> {
    return {
      ...this.inputClasses,
      'w-full': true
    };
  }

  constructor(cdr: ChangeDetectorRef) {
    super(cdr);
    this.iconClass = '';
    this.placeholder = '';
    this.inputClasses = {};
    this.rounded = false;
    this.size = DefaultInputTextSize;
    this.keyFilter = new RegExp('.*');
  }
}
