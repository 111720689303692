<p-password
  (onBlur)="onBlur.emit($event)"
  (onClear)="onClear.emit($event)"
  (onFocus)="onFocus.emit($event)"
  [appendTo]="appendTo"
  [ariaLabel]="ariaLabel"
  [ariaLabelledBy]="ariaLabelledBy"
  [disabled]="disabled"
  [feedback]="feedback"
  [formControl]="formControl"
  [hideTransitionOptions]="hideTransitionOptions"
  [inputId]="inputId"
  [inputStyleClass]="inputStyleClass"
  [inputStyle]="inputStyle"
  [label]="label"
  [maxLength]="maxLength"
  [mediumLabel]="mediumLabel"
  [mediumRegex]="mediumRegex"
  [placeholder]="placeholder"
  [promptLabel]="promptLabel"
  [showClear]="showClear"
  [showTransitionOptions]="showTransitionOptions"
  [strongLabel]="strongLabel"
  [strongRegex]="strongRegex"
  [styleClass]="styleClass"
  [style]="style"
  [toggleMask]="toggleMask"
  [weakLabel]="weakLabel"
  class="w-100"
>
</p-password>
